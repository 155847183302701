/* Estilo para el contenedor del logo */
.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

/* Estilo para el logo */
.logo-404 {
  max-width: 90%;
  max-height: 50%;
  height: auto;
  width: auto;
}


@media (max-width: 600px) {
  .logo-404 {
    max-width: 70%;
    max-height: 40%;
  }
}

@media (max-width: 400px) {
  .logo-404 {
    max-width: 50%;
    max-height: 30%;
  }
}

h1 {
  font-size: 2rem;
  margin: 1rem 0;
}

p {
  font-size: 1rem;
  margin: 0.5rem 0;
}
