/* Header.css */
.app-bar {
  background: #1976d2;
  box-shadow: none;
  position: sticky;
  top: 0;
}

.toolbar-title {
  flex-grow: 1;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.styled-button {
  text-transform: none;
  color: #ffffff;
  font-size: 18px;
  margin: 0 8px;
  padding: 6px 16px;
}

.styled-button:hover {
  background-color: #1565c0;
}
