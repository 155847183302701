.styled-card {
  background: linear-gradient(145deg, #ffffff 0%, #f4f4f4 100%);
  border-radius: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  height: calc(50vh - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.styled-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  background: linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.icon-wrapper svg {
  font-size: 36px;
  color: #2196f3;
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-title {
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #1a237e;
}

.card-description {
  white-space: pre-line;
  text-align: center;
  color: #666666;
  line-height: 1.8;
  margin-bottom: 16px;
}

.card-actions {
  justify-content: center;
}

.styled-button {
  padding: 8px 16px;
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  color: #ffffff;
  text-transform: none;
  font-weight: 600;
  border-radius: 8px;
}

.styled-button:hover {
  background: linear-gradient(45deg, #1976d2 30%, #1ea7c9 90%);
}
