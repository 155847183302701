.verbs-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border: none; /* Quitar el borde */
  border-radius: 24px; /* Borde redondeado */
  background: linear-gradient(145deg, #ffffff 0%, #f4f4f4 100%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px #ccc inset; /* Sombra más suave y borde interior */
  transition: all 0.3s ease-in-out;
}


.image-loader-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.verbs-card-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.verbs-card-image.loaded {
  display: block;
}


.verbs-card-content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verbs-card-title {
  margin: 0.5em 0;
  font-weight: bold;
  color: #1a237e;
}

.verbs-button {
  margin-top: 1em;
  padding: 8px 16px;
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  color: #ffffff;
  text-transform: none;
  font-weight: 600;
  border-radius: 8px;
}

.verbs-button:hover {
  background: linear-gradient(45deg, #1976d2 30%, #1ea7c9 90%);
}
