/* Archivo: vocabulary.css */
.home-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.row {
  display: flex;
  gap: 20px;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 150px; /* Tamaño predeterminado */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.category:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.category img {
  width: 100px; /* Tamaño predeterminado */
  height: 100px; /* Tamaño predeterminado */
  margin-bottom: 10px;
}

.category p {
  margin: 0;
  font-size: 16px;
}

/* Media query para pantallas grandes */
@media (min-width: 1024px) {
  .row {
    display: flex;
    gap: 60px;
    width: 100%;
  }
  .category {
    width: 100%; /* Cada contenedor ocupa un 20% del ancho total */
    max-width: 550px; /* Limita el ancho máximo */
    padding: 20px; /* Espaciado más amplio */
  }

  .category img {
    width: 120px;
    height: 120px;
  }

  .category p {
    font-size: 18px;
  }
}